import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import List from "../components/list"
import Layout from "../components/layout"
import SEO from "../components/seo"

let items = []

const PathPages = ({ data }) => (
  <Layout>
    <SEO title="All Path Pages" />

    <h2 className="subtitle is-3">Path Pages</h2>

    {Object.entries(data.allStrapiPathpage.edges).forEach(([key, document]) => {
      if (
        document.node.url === "none" ||
        document.node.url === undefined ||
        document.node.url === null
      ) {
        items[key] = {
          id: document.node.id,
          link: `/${document.node.id}`,
          title: document.node.Title,
        }
      } else {
        items[key] = {
          id: document.node.id,
          link: `/${document.node.url}`,
          title: document.node.Title,
        }
      }
    })}
    <List items={items} />
  </Layout>
)
PathPages.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PathPages

export const pageQuery = graphql`
  query PathPagesQuery {
    allStrapiPathpage(sort: { fields: Title }) {
      edges {
        node {
          Title
          id
          url
        }
      }
    }
  }
`
